/*!
 * bsStepper v1.7.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - 2019 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
.bs-stepper .step-trigger {
  color: #6c757d;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: none;
  border-radius: .25rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  transition: background-color .15s ease-out, color .15s ease-out;
  display: inline-flex;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
  pointer-events: none;
  opacity: .65;
}

.bs-stepper .step-trigger:focus {
  color: #007bff;
  outline: 0;
}

.bs-stepper .step-trigger:hover {
  background-color: #0000000f;
  text-decoration: none;
}

@media (width <= 520px) {
  .bs-stepper .step-trigger {
    flex-direction: column;
    padding: 10px;
  }
}

.bs-stepper-label {
  margin: .25rem;
  display: inline-block;
}

.bs-stepper-header {
  align-items: center;
  display: flex;
}

@media (width <= 520px) {
  .bs-stepper-header {
    text-align: center;
    margin: 0 -10px;
  }
}

.bs-stepper .line, .bs-stepper-line {
  background-color: #0000001f;
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
}

@media (width <= 400px) {
  .bs-stepper .line, .bs-stepper-line {
    flex-basis: 20px;
  }
}

.bs-stepper-circle {
  color: #fff;
  background-color: #6c757d;
  border-radius: 1em;
  place-content: center;
  width: 2em;
  height: 2em;
  margin: .25rem;
  padding: .5em 0;
  line-height: 1em;
  display: inline-flex;
}

.active .bs-stepper-circle {
  background-color: #007bff;
}

.bs-stepper-content {
  padding: 0 20px 20px;
}

@media (width <= 520px) {
  .bs-stepper-content {
    padding: 0;
  }
}

.bs-stepper.vertical {
  display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane, .bs-stepper.vertical .content {
  display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade), .bs-stepper.vertical .content:not(.fade) {
  visibility: hidden;
  display: block;
}

.bs-stepper .content:not(.fade), .bs-stepper-pane:not(.fade) {
  display: none;
}

.bs-stepper .content.fade, .bs-stepper-pane.fade {
  visibility: hidden;
  transition-property: opacity;
  transition-duration: .3s;
}

.bs-stepper .content.fade.active, .bs-stepper-pane.fade.active {
  visibility: visible;
  opacity: 1;
}

.bs-stepper .content.active:not(.fade), .bs-stepper-pane.active:not(.fade) {
  visibility: visible;
  display: block;
}

.bs-stepper .content.dstepper-block, .bs-stepper-pane.dstepper-block {
  display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none, .bs-stepper:not(.vertical) .content.dstepper-none {
  display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none, .vertical .content.fade.dstepper-none {
  visibility: hidden;
}
/*# sourceMappingURL=index.700d015a.css.map */
